import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import house from '../img/landing1.webp'
import house2 from '../img/landing_house3.jpg'
import interior from '../img/interior_painting.webp'
import interior2 from '../img/interior_painting.jpg'
import window_renovation from '../img/window_renovation.webp'
import window_renovation2 from '../img/window_renovation.jpg'
import tapet from '../img/tapet_rolls.webp'
import tapet2 from '../img/taper_rolls.jpg'
import './services.css'

const Services = () => {
    const handleInterior = (i) => i.target.src = interior2;
    const handleWindow = (i) => i.target.src = window_renovation2;
    const handleHouse = (i) => i.target.src = house2;
    const handleTapet = (i) => i.target.src = tapet2;

    return (
        <Container fluid id="services">
            <h3 className="text-center my-5"><span style={{ borderBottom: 'solid 2px  rgb(237,28,36)' }}>vad vi gör</span></h3>
            <Row className="m-md-5">
                <Col sm={12} md={3}>
                    <img loading="lazy" src={interior} alt="interior" className="serviceImg" onError={handleInterior} />
                    <h4 className="py-2 text-center">Interiörmålning</h4>
                </Col>
                <Col sm={12} md={3}>
                    <img loading="lazy" src={window_renovation} alt="Fonster" className="serviceImg" onError={handleWindow} />
                    <h4 className="py-2 text-center">Fönsterrenovering</h4>
                </Col>
                <Col sm={12} md={3}>
                    <img loading="lazy" src={house} alt="house" className="serviceImg" onError={handleHouse} />
                    <h4 className="py-2 text-center">Hem & lägenhet renovering</h4>
                </Col>
                <Col sm={12} md={3}>
                    <img loading="lazy" src={tapet} alt="tapet" className="serviceImg" onError={handleTapet} />
                    <h4 className="py-2 text-center">Tapet</h4>
                </Col>
            </Row>
        </Container>
    )
}

export default Services
