import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './footer.css';
import logosvg from '../img/MA-Maleri.svg';

const Footer = () => {

    return (
        <div className="my-4 mx-3 mx-md-5 py-4" style={{ borderTop: '2px solid rgba(0,0,0,.8)' }}>
            <Row className="py-1">
                <Col sm={12} md={2} className="text-center py-3 mt-4">
                    <img src={logosvg} alt="" style={{ width: '150px', height: '50px' }} />
                </Col>
                <Col sm={12} md={5}>
                    <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2229.00771976121!2d12.745237815435658!3d56.0358551775936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652331d9dff39fd%3A0x74aef1ce484fb12e!2sAlnarpsgatan%2053%2C%20256%2067%20Helsingborg%2C%20Sweden!5e0!3m2!1sen!2sde!4v1612954300729!5m2!1sen!2sde" frameBorder="0" style={{ width: '100%', height: '100%', border: '0' }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </Col>
                <Col sm={12} md={3}>
                    <h4 className="my-4 text-center"><span className="footerHeaderH4">Kontakt</span></h4>
                    <div className="mx-3">
                        <div className="mt-3 py-1">
                            <i className="footerIcon fas fa-phone" style={{ fontSize: '1rem', width: '20px', textAlign: 'center' }}></i> <a href="tel:0762518359" className="footerLink">0762-518359</a>
                        </div>
                        <div className="py-1">
                            <i className="footerIcon fas fa-envelope" style={{ fontSize: '1rem', width: '20px', textAlign: 'center' }}></i> <a href="mailto:mensur@ma-maleri.se" className="footerLink">mensur@ma-maleri.se <i className="fas fa-external-link-alt"></i></a>
                        </div>

                        <div className="py-1">
                            <i className="footerIcon fas fa-map-marker-alt" style={{ fontSize: '1rem', width: '20px', textAlign: 'center' }}></i> Alnarpsgatan 53, 25667, Helsingborg
                    </div>
                    </div>
                </Col>
                <Col sm={12} md={2} className="text-center">
                    <h4 className="my-4"> <span className="footerHeaderH4"> Instagram </span></h4>
                    <div>
                        <a href="https://www.instagram.com/mamaleri_" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" style={{ fontSize: '25px', width: '60px', textAlign: 'center', textDecoration: 'none', padding: '20px', borderRadius: '50%', background: 'rgba(237,28,36, 1)', color: 'white' }}></i></a>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Footer


{/* <a href="instagram://user?username=mamaleri_"><i className="fab fa-instagram" style={{ fontSize: '30px', width: '60px', textAlign: 'center', textDecoration: 'none', padding: '18px', borderRadius: '50%', background: 'rgba(237,28,36, 1)', color: 'white' }}></i></a> */ }