import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './about.css'

const About = () => {
    return (
        <Container className="about" id="about-us">
            <h3 className="text-center my-5"><span className="" style={{ borderBottom: 'solid 2px  rgb(237,28,36)' }}>Vilka är vi</span></h3>
            <Row className="aboutRow">
                <Col xs={12} md={6} className="p-md-5 rounded aboutRowColText" style={{ backgroundColor: 'rgb(237,28,36)', color: 'white', letterSpacing: '1.5px' }}>
                    Vi är ett modernt målningsföretag som erbjuder målningstjänster med fokus på kvalitet, kunskap och service, bestående av målningspersonal med mer än 20 års erfarenhet. <br /> <br />
                    -Färgproffs är specialister på exteriör- och interiörmålning. Vi utför allt från nybyggnationer och renoveringar till dekorationer och design. <br /><br />
                    -Vi hjälper till med tips och råd för styling av rum med lämpliga färgval och tapeter. Vi bryr oss om miljön och arbetar med miljömärkta färger.
                </Col>
                <Col xs={12} md={6} className="aboutRowColImg">
                    <div className="aboutCar"></div>
                </Col>
            </Row>
        </Container>
    )
}

export default About
