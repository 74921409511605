import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import './landing.css'

const Landing = () => {
    return (
        <div className="landing">
            <div className="landingText">
                <div className="landingTextFirstQuestion">
                    <span>Behöver du ett målningsföretag i eller nära Helsingborg?</span>
                </div>
                <div className="landingTextAnswer py-2">
                    <span>Då är Ma Maleri rätt val för partnerskap.</span>
                </div>
                <Row className="cta-buttons mr-md-5">
                    <Col className="cta-col">
                        <HashLink className="btn rounded my-2 w-100" smooth to="/#about-us">
                            Mer om oss
                        </HashLink>
                    </Col>
                    <Col className="cta-col">
                        <HashLink className="btn rounded my-2 w-100" smooth to="/contact-us">
                            Kontakta oss
                        </HashLink>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Landing
