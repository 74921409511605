import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './contact.css'

const Contact = () => {
    return (
        <Container className="contact">
            <h3 className="text-center">
                <span style={{ borderBottom: '2px solid rgb(237,28,36)' }}>Kontakta oss</span>
            </h3>
            <Row className="contactRow justify-content-center">
                <Col sm={12} md={4} className="mr-md-5 border rounded text-center contactCol" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 className="contactColHeader">Låt oss arbeta ihop</h4>
                    <p>Om du har några frågor är du välkommen att kontakta oss. Du kan nå oss när som helst.</p>
                    <div className="btn border rounded contactBtn">
                        <a href="mailto: mensur@ma-maleri.se">Komma i kontakt</a>
                    </div>
                </Col>
                <Col sm={12} md={4} className="text-center ml-md-5 border rounded contactCol" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 className="contactColHeader">Kontaktperson</h4>
                    <h5 className="pt-3">Mensur Abazi</h5>
                    <p> Ägare & Projektledare <br /> <a href="tel:076251859">0762-518359</a></p>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact
