import React from 'react'
import About from '../Contact_About/About'
import Landing from '../Landing/Landing'
import Services from '../Services/Services'

const Homescreen = () => {
    return (
        <div>
            <Landing />
            <About />
            <Services />
        </div>
    )
}

export default Homescreen
