import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logosvg from '../img/MA-Maleri.svg';
import './header.css'
import { HashLink } from 'react-router-hash-link';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <div>
            <Navbar variant="light" expand="lg" id="navbari" collapseOnSelect>
                <Container>
                    <LinkContainer className="navbar-brand" to="/">
                        <Navbar.Brand className="llogo">
                            <img src={logosvg} alt="llogo" className="" style={{ width: "200px", height: '35px' }} />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="text-center navi ml-auto">
                            <HashLink smooth to="/#about-us">
                                Om oss
                            </HashLink>
                            <HashLink smooth to="/#services">
                                Tjänster
                            </HashLink>
                            <Link to="/contact-us">
                                Kontakta oss
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header
