import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Homescreen from './components/HomeScreen/Homescreen';
import Contact from './components/Contact_About/Contact';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Homescreen} />
        <Route path="/contact-us" component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
